<template>
    <div class="page">
        <div class="content">
            <h2 class="h1">Manutenção Programada</h2>
            <p class="mt8 mb16">Entre {{message}} o Respondi estará em manutenção programada. Durante esse período o sistema está inacecível.</p>
            <small>Sabemos que é chato, mas é importante para manter a saúde do produto :)</small>
            <router-link :to="{name: 'Home'}" class="logo">
                <img src="@/assets/img/respondi-logo-gray.png" alt="Respondi Logo">
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MaintenenceMode",
        computed: {
            message() {
                return process.env.VUE_APP_MAINTENANCE_MODE
            }
        },
    }
</script>

<style lang="sass" scoped>
.page
    background: $cG1
    display: flex
    align-items: center
    justify-content: center
    height: 100vh
    position: relative
    overflow: hidden
    // background-image: url('https://media.giphy.com/media/5IAoDGpfh0c1y/source.gif')
    background-size: cover

    .content
        display: flex
        background: $cWhite
        width: 90%
        max-width: 500px
        height: 350px
        text-align: center
        color: $cBlack
        flex-direction: column
        align-items: center
        justify-content: center
        border-radius: 8px
        z-index: 10
        padding: 0 $mg $mg $mg
        // line-height: 160%
        
        .h1, .h2
            font-weight: normal
        
        .h1
            margin-top: auto
            font-size: $fzXl
            font-weight: bold

        .h2
            font-size: $fzSm * 1.1

        .links
            font-size: $fzXs

        .logo
            margin-top: auto
            width: 120px
            height: auto

        .link
            text-decoration: underline


</style>